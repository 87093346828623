<template>
  <item-text :text="notes" />
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "ShowCustomerNotes",
  components: {
    ItemText,
  },
  props: {
    notes: {
      type: String,
      required: true,
    }
  }
}
</script>

<style>

</style>