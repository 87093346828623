<template>
  <form v-if="customer" class="space-y-6 w-full divide-y divide-gray-200 dark:divide-gray-700">

    <div class="grid grid-cols-1 gap-6">
      <div class="col-span-1" style="height: 100px">
        <Textarea placeholder="Piezīmes" v-model="form.notes" :errors="errors.notes" :showPlaceholder="false"/>
      </div>
    </div>

    <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
      <template v-if="!loading">
        <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
          Saglabāt
        </button>
      </template>
      <template v-else>
        <Loading />
      </template>

      <button @click.prevent="hideAddForm" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
        Atcelt
      </button>

    </div>

  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Textarea from "@/components/Components/Textarea"
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "AddCustomerNote",
  components: {
    Textarea,
    Loading
  },
  props: {
    customer: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      form: {
        notes: "",
      }
    };
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      errors: "errors"
    }),
    formValidation() {
      return {
        notes: {
          rules: ['required']
        },
      }
    }
  },
  methods: {
    hideAddForm() {
      this.$store.dispatch("removeAllFormsForDisplay");
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch("updateCustomer", {
          id: this.customer.id,
          notes: this.form.notes,
        });
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>

<style>

</style>