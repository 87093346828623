<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Piezīmes
      </h3>
    </template>
    <template v-slot:buttons>
      <template v-if="customer.notes && !displayForm('EditNotes')">
        <Button icon="pencil" @click="showForm('EditNotes')" />
      </template>
    </template>
    <template v-slot:content>

      <template v-if="customer.notes">
        <template v-if="!displayForm('EditNotes')">
          <ShowCustomerNotes :notes="customer.notes" />
        </template>
        <template v-else>
          <EditCustomerNote :customer="customer" />
        </template>
      </template>

      <template v-else>
        <template v-if="!displayForm('AddNotes')">
          <div class="w-full">
            <p class="text-center text-lg text-gray-400 mb-3">Klientam nav pievienotas piezīmes</p>
            <button @click="showForm('AddNotes')" type="button" class="block mx-auto items-center px-3 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 hover:bg-gray-100 transition duration-500 ease-in-out">
              Pievienot
            </button>
          </div>
        </template>

        <template v-else>
          <AddCustomerNote :customer="customer" />
        </template>
      </template>

    </template>
  </item-card>
</template>

<script>
import { mapGetters } from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import AddCustomerNote from "@/components/Customer/CustomerDetails/Notes/AddCustomerNote"
import ShowCustomerNotes from "@/components/Customer/CustomerDetails/Notes/ShowCustomerNotes"
import EditCustomerNote from "@/components/Customer/CustomerDetails/Notes/EditCustomerNote"

export default {
  name: "CustomerDetailNotes",
  components: {
    ItemCard,
    AddCustomerNote,
    ShowCustomerNotes,
    EditCustomerNote,
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay'
    }),
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  },
}
</script>

<style>

</style>